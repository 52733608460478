<template>
  <div class="section-content section-pickup pt-4 pb-5 w-100 container">
    <b-container class="container-xlg m-auto pl-xxl-0 pr-xxl-0">
      <div class="top-event d-flex flex-column flex-md-column">
        <esports-title
          title="Rule"
          class="pb-2 pt-2 mb-2 mt-2 mt-xxl-4 pb-xxl-5 article-page"
          sub_title="利用規約"
        />
      </div>
      <div class="term-content">
          <p>この規約（以下「本規約」といいます。）は、ユーザー（第1条で定義します）による株式会社メルメクス（以下「当社」といいます。）が運営するインターネットサービス「Donate + eSports」の利用の諸条件を定めるものです。 ユーザーは、本サービス（第1条で定義します）を利用した時点で、本規約に同意したものとみなします。</p>
          <p>
                <b>第１条 定義</b> <br />
                本規約上で使用する用語の定義は、次に掲げる通りとします。<br />
                （1）「本サービス」とは、 当社が運営するインターネットサービス及び関連するサービスを指します。<br />
                （2）「本サイト」とは、 本サービスのコンテンツが掲載されたウェブサイト及びアプリを指します。<br />
                （3）「本コンテンツ」とは、本サービス上で提供される文字、音、静止画、動画、ソフトウェアプログラム、コード等の総称を指します。<br />
                （4）「ユーザー」とは、本規約の内容に同意して、本サービスを利用する個人を指します。<br />
                （5）「ＩＤ」とは、 本サービスの利用のためにユーザーが固有に持つ文字列を指します。<br />
                （6）「パスワード」とは、 ＩＤに対応してユーザーが固有に設定する暗号を指します。<br />
                （7）「個人情報」とは、 住所、氏名、職業、電話番号等個人を特定することのできる情報の総称で、個人情報の保護に関する法律にいう「個人情報」を指します。<br />
                （8）「登録情報」とは、 ユーザーが本サイトにて登録した情報の総称を指します。<br />
                （9）「投票券」とは、当社の指定するゲームに参加する際に必要なアイテムを指します。<br />
                （10）「大会」とは、当社の指定するeSportsの大会を指します。<br />
                （11）「大会参加者」とは、大会に参加する選手を指します。<br />
                （12）「知的財産」とは、発明、考案、植物の新品種、意匠、著作物その他の人間の創造的活動により生み出されるもの（発見又は解明がされた自然の法則又は現象であって、産業上の利用可能性があるものを含みます）、商標、商号その他事業活動に用いられる商品又は役務を表示するもの及び営業秘密その他の事業活動に有用な技術上又は営業上の情報を指します。 <br />
                （13）「知的財産権」とは 特許権、実用新案権、育成者権、意匠権、著作権、商標権その他の知的財産に関して法令により定められた権利又は法律上保護される利益に係る権利を指します。
          </p>
          <p>
                <b>第２条 利用資格</b><br />
                1　当社は、当社とその子会社若しくは関連会社の役職員又は当該役職員の近親者に該当する者が本サービスを利用することを禁止するものとします。<br />
                2　前項の規定にかかわらず、当社、その子会社又は関連会社の役職員については、本サービスの向上のために必要な限度で、本サービスの一部を利用することができるものとします。但し、これらの者及び大会参加者は、本サービスの利用に伴い、第10条に定めるドネトを獲得する権利をいかなる方法によっても取得せず又は行使しないこととします。
          </p>
          <p>
                <b>第３条 本規約への同意</b><br />
                1　ユーザーは、本利用規約に同意した上で、本サービスを利用するものとします。<br />
                2　ユーザーが、本サービスをスマートフォンその他の情報端末からアクセスし、利用した時点で、ユーザーと当社との間で、本規約の諸規定に従った利用契約が成立するものとします。
          </p>
          <p>
                <b>第４条 本規約の変更</b><br />
                1　当社は、お客様の事前の同意を要することなく、またお客様に事前の通知を行うことなく、随時、本規約の内容を追加または変更等できるものとし、お客様はこれを承諾します。<br />
                2　本規約を改訂した場合、本サービスの利用に関する条件は改訂後の本規約に拠るものとします。
          </p>
          <p>
                <b>第５条　ユーザーの登録及びアカウント</b><br />
                1　本サービスの利用を希望する方（以下「登録希望者」といいます。）は、本規約に同意した上、当社の定める手続きにより登録を申し込むものします。<br />
                2　ユーザーは、利用に際して登録した情報（以下「登録情報」といいます。メールアドレスや電話番号・ID・パスワード等を含みます）について、自己の責任の下、任意に登録、管理するものとします。ユーザーは、これを第三者に利用させることや、貸与、譲渡、名義変更、売買、賃貸その他形態を問わず処分することは出来ません。<br />
                3　ユーザーは、登録情報の不正使用によって当社又は第三者に損害が生じた場合、当社及び第三者に対して、当該損害を賠償するものとします。<br />
                4　登録情報の管理は、ユーザーが自己の責任の下で行うものとし、登録情報が不正確又は虚偽であったためにユーザーが被った一切の不利益及び損害に関して、当社は責任を負わないものとします。<br />
                5　登録情報が盗用され又は第三者に利用されていることが判明した場合、ユーザーは直ちにその旨を当社に通知するとともに、当社からの指示に従うものとします。<br />
                6　当社は登録希望者が、以下の各号のいずれかの事由に該当する場合は、登録の申請を承認しないことがあります。<br />
                （1）第2条のユーザー資格を満たしていない場合<br />
                （2）過去に本規約違反等により、当社から利用停止等の処分を受けている場合<br />
                （3）登録内容に正確ではない情報又は虚偽の情報が含まれている場合、又はそれらのおそれがある場合<br />
                （4）当社の運営、サービス提供若しくは他のユーザーの利用を妨害する又はそれらに支障をきたす行為を行った場合やそのおそれがあると当社が合理的な理由に基づき判断した場合<br />
                （5）反社会的勢力等（暴力団、暴力団員、右翼団体、反社会的勢力、その他これに準ずるものを意味します。以下同じです）である、又は、資金提供その他を通じて反社会的勢力等の維持、運営若しくは経営に協力若しくは関与する等反社会的勢力等との何らかの交流若しくは関与を行っていると当社が判断した場合<br />
                （6）本規約に違反するおそれがある場合<br />
                （7）その他当社が不適当であると合理的な理由に基づき判断する場合
          </p>
          <p>
                <b>第６条　個人情報等の取扱い</b><br />
                ユーザーの個人情報については、当社が別途定める「Donate + eSportsプライバシーポリシー」に則り、適正に取り扱うこととします。
          </p>
          <p>
                <b>第７条　禁止行為</b><br />
                本サービスの利用に際し、当社は、ユーザーに対し、次に掲げる行為を禁止します。当社において、ユーザーが禁止事項に違反したと認めた場合、事前に通知又は催告をすることなく、本サービスの利用停止、ユーザーとしての登録の取り消し等、当社において必要と判断した措置を取ることができるものとし、ユーザーはこれを異議なく同意するものとします。<br />
                （1）当社又は第三者の知的財産権を侵害する行為<br />
                （2）当社又は第三者の名誉・信用を毀損又は不当に差別若しくは誹謗中傷する行為<br />
                （3）当社又は第三者の財産を侵害する行為、又は侵害する恐れのある行為<br />
                （4）当社又は第三者に経済的損害を与える行為<br />
                （5）当社又は第三者に対する脅迫的な行為<br />
                （6）コンピューターウィルス、有害なプログラムを仕様又はそれを誘発する行為<br />
                （7）本サービス用インフラ設備に対して過度な負担となるストレスをかける行為<br />
                （8）当サイトのサーバーやシステム、セキュリティへの攻撃<br />
                （9）当社提供のインターフェース以外の方法で当社サービスにアクセスを試みる行為<br />
                （10）第5条第2項に該当する行為<br />
                （11）第8条第2項に該当する行為<br />
                （12）未成年者のユーザーが法定代理人の同意を得ずに本サービスを利用する行為<br />
                （13）上記の他、当社が不適切と判断する行為<br />
          </p>
          <p>
                <b>第８条　大会について</b><br />
                1　ユーザーは、本サービスにアクセスし、参加可能な大会を見ることができます。大会参加者に投票するためには投票券が必要です。<br />
                2　第2条、第5条第2項に該当するユーザーは大会への投票ができないものとします。<br />
                3　大会は、当社で指定した期間内で投票が可能です。締め切りが過ぎたゲームには投票ができないものとします。<br />
                4　大会の結果は、大会終了後48時間以内に確定された試合結果に基づき算出、確定されるものとし、一度確定した大会結果は、その後、対象試合の開催主体によって試合結果の調整、修正が行われたとしても、変更されることはないものとします。<br />
                5　当社は、合理的な理由に基づき相当と判断した場合には、大会への投票を中止できるものとします。その場合、消費された投票券はユーザーへ返還するものとします。
          </p>
          <p>
                <b>第９条　投票券について</b><br />
                1　ユーザーは、当社が定める方法により、投票券を入手することが出来るものとします。<br />
                2　ユーザーは、前項の方法以外に、当社が定める一定の場合に、投票券を入手することができます。<br />
                3　ユーザーは、投票券を他のユーザー、その他第三者に使用させ、また貸与、譲渡、売買、質入等をすることはできないものとします。<br />
                4　投票券の有効期限は、無期限とします。但し、ユーザーが理由の如何を問わず退会等により本サービスの利用資格を喪失した場合は、未使用分の投票券も消滅するものとします。<br />
          </p>
          <p>
                <b>第１０条　ドネトについて</b><br />
                1　優勝した大会参加者に投票した人の中から、当社が定める抽選方法によって選ばれたユーザーに、大会毎に事前に設定された条件を基に算出されたドネトを付与します。<br />
                2　ユーザーは、本サービスで獲得したドネトについて、当社が定める手続き及び方法により、Amazonギフトへの交換申請を行うことができるものとします。<br />
                3　ドネトの有効期限の起算日は、最後に本サービスへログインをしてから180日までとなります。<br />
                4　本サービス内で保有しているドネトは最後に本サービスへログインしてから180日以内にのみAmazonギフトへの交換申請を行うことができるものとします。<br />
                5　本サービスへの最後のログインから180日を経過したドネトは消滅し、その後はAmazonギフトへの交換申請をすることはできないものとします。
          </p>
          <p>
                <b>第１１条　換金行為の禁止等</b><br />
                ユーザーが本サービス内で取得した一切のコンテンツ、ドネト、投票券については、ユーザー間の直接取引、第三者による仲介取引又はインターネットオークション等その手段を問わず、売買等、金銭その他の経済的な対価の授受を伴うあらゆる譲渡、貸与等を禁止します。
          </p>
          <p>
                <b>第１２条　サービス提供条件</b><br />
                1　当社はメンテナンス等のために、ユーザーに通知することなく、本サービスを停止又は変更することがあります。<br />
                2　本サービスを利用するために必要な機器、通信手段などはユーザーの費用と責任で備えるものとします。<br />
                3　当社は、本サービスに中断、中止その他障害が生じないことを保証しません。
          </p>
          <p>
                <b>第１３条 　コンテンツの取扱い</b><br />
                1　ユーザーは､本サービスのコンテンツを当社の定める範囲内でのみ使用することができるものとします｡<br />
                2　本サービスで提供される全てのコンテンツに関する権利は当社が保有しており､ユーザーに対し､当社が保有する特許権､実用新案権､ 意匠権､商標権､著作権､その他知的財産権の実施又は使用許諾をするものではありません｡<br />
                3　ユーザーは､当社の定める使用範囲を超えていかなる方法によっても複製､送信､譲渡（ユーザー同士の売買も含みます）､貸与､翻訳､翻案、無断で転載、二次使用、営利目的の使用、改変、逆アセンブル、逆コンパイル、リバースエンジニアリング等を行う事を禁止します。<br />
                4　前項に関わらず､退会等によりユーザーがユーザー資格を喪失した場合は､提供されたコンテンツの使用権も消滅するものとします｡
          </p>
          <p>
                <b>第１４条　免責</b><br />
                1　当社は、本サービスの内容変更、中断及び終了によって生じたいかなる損害についても、一切責任を負いません。<br />
                2　当社は、ユーザーの本サービスの利用環境について一切関与せず、また一切の責任を負いません。<br />
                3　当社は、本サービスがユーザーの特定の目的に適合すること、期待する機能・商品的価値・正確性・有用性を有すること、ユーザーによる本サービスの利用がユーザーに適用のある法令又は業界団体の内部規則等に適合すること、及び不具合が生じないことについて、何ら保証するものではありません。<br />
                4　当社は、本サービスが全ての情報端末に対応していることを保証するものではなく、本サービスの利用に供する情報端末のOSのバージョンアップ等に伴い、本サービスの動作に不具合が生じる可能性があることにつき、ユーザーはあらかじめ了承するものとします。当社は、かかる不具合が生じた場合に当社が行うプログラムの修正等により、当該不具合が解消されることを保証するものではありません。<br />
                5　ユーザーは、本サービスの一部又は全部の利用が制限される可能性があることをあらかじめ了承するものとします。<br />
                6　当社は、本サービスを利用したことにより直接的又は間接的にユーザーに発生した損害について、一切賠償責任を負いません。<br />
                7　当社は、ユーザーその他の第三者に発生した機会逸失、業務の中断その他いかなる損害（間接損害や逸失利益を含みます）に対して、当社が係る損害の可能性を事前に通知されていたとしても、一切の責任を負いません。<br />
                8　ユーザーと他のユーザーとの間の紛争及びトラブルについて、当社は一切責任を負わないものとします。ユーザーと他のユーザーでトラブルになった場合でも、両者同士の責任で解決するものとし、当社には一切の請求をしないものとします。<br />
                9　第1項乃至前項の規定は、当社に故意又は重過失が存する場合は適用しません。<br />
                10　第1項乃至8項が適用される場合であっても、当社は、当社の過失（重過失を除きます）による行為によってユーザーに生じた損害については、特別な事情から生じた損害を除き、賠償する責任を負うものとします。<br />
                11　本サービスの利用に関し当社が損害賠償責任を負う場合、当該損害が発生した月にユーザーから受領した利用額を限度として賠償責任を負うものとします。但し、当社に故意又は重過失が存する場合はこの限りではありません。<br />
                12　ユーザーは、本サービスの利用に関連し、他のユーザーに損害を与えた場合又は第三者との間に紛争を生じた場合、自己の費用と責任において、かかる損害を賠償又はかかる紛争を解決するものとし、当社には一切の迷惑や損害を与えないものとします。<br />
                13　ユーザーの行為により、第三者から当社が損害賠償等の請求をされた場合には、ユーザーの費用（弁護士費用）と責任で、これを解決するものとします。当社が、当該第三者に対して、損害賠償金を支払った場合には、ユーザーは、当社に対して当該損害賠償金を含む一切の費用（弁護士費用及び逸失利益を含みます）を支払うものとします。<br />
                14　ユーザーが本サービスの利用に関連して当社に損害を与えた場合、ユーザーの費用と責任において当社に対して損害を賠償（訴訟費用及び弁護士費用を含みます）するものとします。<br />
          </p>
          <p>
                <b>第１５条　広告の掲載について</b><br />
                ユーザーは、本サービス上にあらゆる広告が含まれる場合があること、当社又はその提携先があらゆる広告を掲載する場合があることを理解しこれを承諾したものとみなします。本サービス上の広告の形態や範囲は、当社によって随時変更されます。
          </p>
          <p>
                <b>第１６条　サービスの終了</b><br />
                1　当社は、ユーザーへの適宜の方法で通知することにより、本サービスを終了することができるものとします。<br />
                2　ユーザーは、本サービスが終了した場合、投票券及びドネトを利用する権利（ドネトの交換申請によりAmazonギフト券を受け取ることができる権利を含みます）一切を失い、以後、当該投票券及びドネトを利用できなくなることをあらかじめ異議なく同意するものとします。<br />
          </p>
          <p>
                <b>第１７条　退会</b><br />
                1　ユーザーは、当社の定める手続きにより退会することができます。<br />
                2　当社は、ユーザーが退会した場合も当社が受領した投票券及びドネトを返還しないものとします。<br />
          </p>
          <p>
                <b>第１８条　分離可能性</b><br />
                本規約のいずれかの条項又はその一部が、消費者契約法その他の法令等により無効又は執行不能と判断された場合であっても、本規約の残りの規定及び一部が無効又は執行不能と判断された規定の残りの部分は、継続して完全に効力を有するものとします。
          </p>
          <p>
                <b>第１９条　当社への連絡方法</b><br />
                本サービスに関するユーザーの当社への連絡・問い合わせは、本サービス又は当社が運営するwebサイト内の適宜の場所に設置するお問い合わせフォームからの送信又は当社が別途指定する方法により行うものとします。
          </p>
          <p>
                <b>第２０条　準拠法、管轄裁判所</b><br />
                1　本規約の有効性、解釈及び履行については、日本法に準拠し、日本法に従って解釈されるものとします。<br />
                2　当社とユーザー等との間での論議・訴訟その他一切の紛争については、訴額に応じて、東京簡易裁判所又は東京地方裁判所を専属的合意管轄裁判所とします｡
          </p>
      </div>
    </b-container>
  </div>
</template>

<script>
export default {
    name: "SectionTerm"
}
</script>